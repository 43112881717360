import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { differenceInSeconds, format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';

import { type DtoRoleplayHistory } from '@lp-lib/api-service-client/public';

import { CopyButton } from '../components/common/CopyButton';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';
import { TimeUtils } from '../utils/time';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const id = action.params.id;
  if (!id) {
    throw new Error('No id provided');
  }

  const history = await tokenWithRedirect(
    async () => {
      const resp = await apiService.block.getRoleplayHistory(id);
      return resp.data.history;
    },
    action.request.url,
    {
      preferRedirect: 'login',
      requireAuthentication: true,
    }
  );

  return {
    history,
  };
}

function TabButton(props: {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) {
  return (
    <button
      type='button'
      onClick={props.onClick}
      className={`px-4 py-2 text-sm font-medium rounded-t-lg transition-colors
    ${
      props.active
        ? 'bg-dark-gray text-white border-gray-700'
        : 'text-icon-gray hover:text-white hover:bg-dark-gray'
    }`}
    >
      {props.children}
    </button>
  );
}

function EvaluationTab(props: { history: DtoRoleplayHistory }) {
  const { history } = props;

  const successCount =
    history.evaluation?.results.filter((r) => r.result === 'success').length ??
    0;
  const totalCount = history.evaluation?.results.length ?? 0;

  return (
    <div className='w-full flex flex-col gap-8'>
      <section className='w-full flex flex-col gap-4'>
        <div className='text-2xl font-bold flex items-center gap-2'>
          Criteria evaluation
          <span className='text-base text-icon-gray'>
            ({successCount} of {totalCount} successful)
          </span>
        </div>
        <div className='bg-dark-gray rounded-lg border border-gray-700 p-6 flex flex-col gap-4'>
          {history.evaluation?.results.map((result, index) => (
            <div
              key={result.id || index}
              className='max-w-200 flex flex-col gap-2'
            >
              <div className='text-icon-gray font-bold'>{result.name}</div>

              <div className='flex flex-col gap-1'>
                <div className='text-sm flex gap-2'>
                  <span className='w-20 font-bold text-right'>Prompt:</span>
                  <span className='flex-1 whitespace-pre-line'>
                    {result.prompt}
                  </span>
                </div>
                <div className='text-sm flex gap-2'>
                  <span className='w-20 font-bold text-right'>Result:</span>
                  <span
                    className={
                      result.result === 'success'
                        ? 'text-green-500'
                        : 'text-red-500'
                    }
                  >
                    {capitalize(result.result)}
                  </span>
                </div>
                {result.reason && (
                  <div className='text-sm flex gap-2'>
                    <span className='w-20 font-bold text-right'>Reason:</span>
                    <span className='flex-1 whitespace-pre-line'>
                      {result.reason}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}

          {!history.evaluation && (
            <div className='text-icon-gray'>No evaluation available</div>
          )}
        </div>
      </section>
    </div>
  );
}

function BasicInfoSection(props: { history: DtoRoleplayHistory }) {
  const { history } = props;

  return (
    <section className='w-full flex flex-col gap-4'>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-4 bg-dark-gray rounded-lg border border-gray-700 p-6'>
        <div>
          <div className='text-icon-gray font-bold'>Block Title</div>
          <div className='truncate'>{history.blockName || 'Untitled'}</div>
        </div>
        <div>
          <div className='text-icon-gray font-bold'>User</div>
          <div className='truncate'>{history.userName}</div>
        </div>
        <div>
          <div className='text-icon-gray font-bold'>Started At</div>
          <div>{format(new Date(history.startedAt), 'yyyy-MM-dd HH:mm')}</div>
        </div>
        <div>
          <div className='text-icon-gray font-bold'>Duration</div>
          <div>
            {TimeUtils.DurationFormattedHHMMSS(
              differenceInSeconds(
                new Date(history.endedAt),
                new Date(history.startedAt)
              ) * 1000
            )}
          </div>
        </div>
      </div>

      {history.rating && (
        <div className='flex flex-col gap-4 bg-dark-gray rounded-lg border border-gray-700 p-6'>
          <div>
            <div className='text-icon-gray font-bold'>Score (1-5)</div>
            <div className='truncate'>{history.rating.score}</div>
          </div>
          {history.rating.comment && (
            <div>
              <div className='text-icon-gray font-bold'>Comment</div>
              <div className='truncate'>{history.rating.comment}</div>
            </div>
          )}
        </div>
      )}
    </section>
  );
}

function ConversationTab(props: { history: DtoRoleplayHistory }) {
  const { history } = props;

  const handleDownloadTranscript = () => {
    const blob = new Blob(
      [history.transcript.map((t) => `${t.speaker}: ${t.text}`).join('\n\n')],
      {
        type: 'text/plain',
      }
    );
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${history.blockName} transcript.txt`;
    a.click();
  };

  return (
    <div className='flex flex-col gap-8'>
      <section className='w-full flex flex-col gap-4'>
        <div className='flex justify-between items-end'>
          <div className='text-2xl font-bold'>Transcript</div>
          <button
            type='button'
            onClick={handleDownloadTranscript}
            className='text-primary underline'
          >
            Download
          </button>
        </div>

        <div className='max-h-[70vh] bg-dark-gray overflow-scroll scrollbar rounded-lg border border-secondary shadow-md p-6 flex flex-col gap-4'>
          {history.transcript.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.speaker === 'assistant' ? '' : 'flex-row-reverse'
              }`}
            >
              <div
                className={`max-w-[70%] rounded-2xl p-4 ${
                  message.speaker === 'assistant'
                    ? 'bg-dark-gray border border-gray-700 text-white'
                    : 'bg-blue-600 text-white'
                }`}
              >
                <p className='whitespace-pre-wrap text-sm'>{message.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className='w-full flex flex-col gap-4'>
        <div className='text-2xl font-bold'>Feedback</div>
        <div className='bg-dark-gray rounded-lg border border-secondary shadow-md p-6 flex flex-col gap-4'>
          <div>
            <div className='text-icon-gray font-bold'>Result</div>
            <div>{capitalize(history.feedback?.result)}</div>
          </div>

          <div>
            <div className='text-icon-gray font-bold'>Success Criteria</div>
            <ul className='list-disc list-inside'>
              {history.feedback?.successCriteria.map((c, index) => (
                <li key={`success-criteria-${index}`}>
                  <span className='font-bold'>[{capitalize(c.result)}]</span>{' '}
                  {c.description}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <div className='text-icon-gray font-bold'>Failure Criteria</div>
            <ul className='list-disc list-inside'>
              {history.feedback?.failureCriteria.map((c, index) => (
                <li key={`failure-criteria-${index}`}>
                  <span className='font-bold'>[{capitalize(c.result)}]</span>{' '}
                  {c.description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className='w-full flex flex-col gap-4'>
        <div className='flex justify-between items-end'>
          <div className='text-2xl font-bold'>Instructions</div>
          <CopyButton
            copiedText={history.instructions}
            className='text-primary underline'
          >
            Copy
          </CopyButton>
        </div>
        <div className='max-h-100 overflow-scroll scrollbar bg-dark-gray rounded-lg border border-secondary shadow-md p-6 flex flex-col gap-4'>
          <div className='whitespace-pre-wrap'>{history.instructions}</div>
        </div>
      </section>
    </div>
  );
}

interface RoleplayHistoryDetailProps {
  history: DtoRoleplayHistory;
}

function RoleplayHistoryDetail(props: RoleplayHistoryDetailProps) {
  const [activeTab, setActiveTab] = useState<'conversation' | 'evaluation'>(
    'conversation'
  );

  return (
    <div className='w-full h-full overflow-scroll scrollbar text-white flex justify-center'>
      <div className='w-full max-w-screen-xl flex flex-col gap-8 p-10'>
        <header className='w-full flex justify-between items-center'>
          <div className='text-3xl font-bold'>Roleplay Details</div>
        </header>

        <BasicInfoSection {...props} />

        <div className='flex gap-1 border-b border-gray-700'>
          <TabButton
            active={activeTab === 'conversation'}
            onClick={() => setActiveTab('conversation')}
          >
            Conversation
          </TabButton>
          <TabButton
            active={activeTab === 'evaluation'}
            onClick={() => setActiveTab('evaluation')}
          >
            Evaluation
          </TabButton>
        </div>

        {activeTab === 'conversation' && <ConversationTab {...props} />}
        {activeTab === 'evaluation' && <EvaluationTab {...props} />}
      </div>
    </div>
  );
}

export function Component() {
  const { history } = useLoaderData<typeof clientLoader>();

  return <RoleplayHistoryDetail history={history} />;
}
